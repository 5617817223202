import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react';
import { FormEvent, FunctionComponent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Shipment } from '../../types/Shipment';
import { MainInformationContent } from './MainInformationContent';
import '../../theme/styles.css';

interface IMainInformationProps {
  shipment: Shipment | null;
  search: string;
  setSearch: (search: string) => void;
  onSearchShipment: (queryString: string) => void;
  resetSearchParams: () => void;
}

export const MainInformation: FunctionComponent<IMainInformationProps> = ({
  shipment,
  search,
  setSearch,
  onSearchShipment,
  resetSearchParams,
}) => {
  const { t } = useTranslation();

  const inputReference = useRef<HTMLInputElement>(null);

  const onSubmit = (event: FormEvent) => {
    inputReference.current?.blur();
    event.preventDefault();
    onSearchShipment(search);
  };

  const onResetSearch = () => {
    resetSearchParams();
    setSearch('');
    inputReference.current?.focus();
  };

  return (
    <Flex
      flexDirection="column"
      as="section"
      layerStyle="primaryGradient"
      borderRadius="16"
      alignItems="center"
      paddingTop="5"
      paddingBottom="8"
      paddingX="5"
      mb="4"
      fontSize="3xl"
    >
      <HStack
        as="form"
        onSubmit={(event) => onSubmit(event)}
        width="100%"
        alignItems="baseline"
        action="#"
      >
        <InputGroup marginBottom="6">
          <InputLeftElement
            pointerEvents="none"
            children={
              <SearchIcon marginTop="2" marginLeft="1" color="gray.500" />
            }
          />
          <Input
            ref={inputReference}
            variant="search"
            type="search"
            size="lg"
            fontSize="lg"
            value={search}
            onChange={(event) =>
              setSearch(event.target.value?.replaceAll(' ', ''))
            }
            placeholder={t('Search for Röhlig Ref. or B/L')}
            fontWeight="500"
          />
          {search !== '' && (
            <InputRightElement
              children={
                <Button
                  marginTop="2"
                  marginRight="1"
                  background="transparent"
                  onClick={onResetSearch}
                >
                  <CloseIcon color="gray.500" />
                </Button>
              }
            />
          )}
        </InputGroup>
      </HStack>
      <MainInformationContent shipment={shipment} />
    </Flex>
  );
};
